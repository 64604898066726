import React, { useContext, useState } from 'react';
import { navigate } from 'gatsby';
import { ErrorContext } from '../contexts/ErrorContext';
import Container from '../components/shared/Container';
import { ISignInFormFields } from '../models';
import SignInForm from '../components/SignInForm';
import PageHeader from '../components/shared/PageHeader';
import styled from 'styled-components';
import {
  removeCurrentRoom,
  saveName,
  saveProductUserId,
  saveProfession
} from '../services/localStorage';
import firebase from 'gatsby-plugin-firebase';
import db from '../services/firebase';
import { authenticate } from '../api';

const initialValues: ISignInFormFields = {
  email: '',
  password: ''
};

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const SignIn: React.FC = () => {
  const { handleError } = useContext(ErrorContext);
  const [values, setValues] = useState<ISignInFormFields>(initialValues);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (name: string) => ({
    target
  }: React.ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [name]: name === 'canContact' ? target.checked.toString() : target.value
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const res = await firebase
        .auth()
        .signInWithEmailAndPassword(values.email, values.password);
      if (!res) {
        handleError('Could not sign in...');
        return;
      }
      const data = await db.getUser(res.user.uid);
      if (!data) {
        handleError('Could not sign in and retrieve user info...');
        return;
      }
      saveName(data.nickname);
      saveProfession(data.fieldOfExpertise);
      saveProductUserId(data.gwenId);
      removeCurrentRoom();
      await authenticate(data.gwenId);

      setIsLoading(false);
      navigate('/');
    } catch (error) {
      setIsLoading(false);
      if (error.code === 'auth/user-not-found') {
        handleError('There exist no user with the given email...');
      } else {
        handleError('Wrong credentials...');
      }
    }
  };

  return (
    <StyledContainer>
      <PageHeader title="Sign In" />
      <SignInForm
        values={values}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isLoading={isLoading}
      />
    </StyledContainer>
  );
};

export default SignIn;
