import React from 'react';
import { ISignInFormFields } from '../models';
import Button from './shared/Button';
import Spacer from './shared/Spacer';
import InputField from './shared/InputField';
import styled from 'styled-components';
import { isEmailValid } from '../services/validations';

interface ISignInForm {
  handleChange: (
    name: string
  ) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent) => void;
  values: ISignInFormFields;
  isLoading: boolean;
}

const StyledForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;

const SignInForm: React.FC<ISignInForm> = ({
  handleSubmit,
  handleChange,
  values,
  isLoading
}) => (
  <StyledForm
    name="register"
    method="POST"
    data-netlify="true"
    onSubmit={handleSubmit}
  >
    <InputField
      label="Email"
      type="email"
      name="email"
      onChange={handleChange('email')}
      value={values.email}
    />
    <Spacer spacing={2} />
    <InputField
      label="Password"
      type="password"
      name="password"
      onChange={handleChange('password')}
      value={values.password}
    />
    <Spacer spacing={6} />
    <Button
      primary
      loading={isLoading}
      type="submit"
      disabled={!isEmailValid(values.email) || values.password === ''}
    >
      Continue
    </Button>
  </StyledForm>
);

export default SignInForm;
